@font-face {
  font-family: TitleFont;
  src: url("Moderna.TTF");
}
.App {
  text-align: center;
  
  
  
  
}




.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



body {
  margin: 0;
  overflow:hidden;
  background: linear-gradient(70deg,rgb(61, 222, 250),rgb(69, 192, 245), rgb(61, 222, 250));
  background-repeat: no-repeat;
  color:white;
  width: 100vw;
  height: 100vh;
  
}
#Container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-template-areas: 
  "sector1 sector2";
}

#Main {
  grid-area: sector2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  
}

#Input {
  border: solid;
  border-radius: 10px;
  border-color: transparent;
  width: 30vw;
  height: 4vh;
  font-size: 19px;
  z-index: 20;
  border: solid;
  border-color: rgb(69, 192, 245);
  
}

#Input:focus {
  border-color: rgb(69, 192, 245);
  outline-color: rgb(69, 192, 245);
}

#Submission-Container {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: 
  "name"
  "email";
  width:35vw;
  height: 35vh;
  background-color: white;
  border: solid;
  border-radius: 10px;
  border-color: white;
  border-width: 3px;
  z-index: 10;
  box-shadow: 2px 2px 4px white;
  

}

#Name {
  grid-area: name;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 20px;
  
  
  
}

#Email {
  grid-area: email;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 15px;
  
  
  
}

#Submit-Button {
  width: 5vw;
  height: 3vh;
  scale: calc(1.2);
  background-color: rgb(69, 192, 245);
  color: white;
  border: solid;
  border-radius: 5px;
  border-color: transparent;
  z-index: 20;

}

#Submit-Button:hover {
  scale: calc(1.25);
}






h1 {
  font-size: 120px;
  font-family: TitleFont;
  
  
}

#Iphone {
  grid-area: sector1;
  /*display: flex;
  flex-direction: center;
  justify-content: center;*/
  position: absolute;
  right: 300px;
  bottom: 70px;
  
  

}





